import React from 'react';
import QRCode from 'react-qr-code';

import { IShippingLabelMappedData } from '../../../interfaces';
import S from '../ShippingLabelPrintTemplate.styles';

interface ShippingLabelContentProps {
  data: IShippingLabelMappedData;
}

export const ShippingLabelContent: React.FC<ShippingLabelContentProps> = ({
  data,
}) => (
  <>
    <S.ContentGrid item xs={3}>
      Date
    </S.ContentGrid>
    <S.ContentGrid item xs={6}>
      : {data.order_date}
    </S.ContentGrid>
    <S.TopRightInformation item xs={3}>
      {data.priority}
    </S.TopRightInformation>

    <S.ContentGrid item xs={3}>
      Invoice Number
    </S.ContentGrid>
    <S.ContentGrid item xs={6}>
      : {data.invoice_number}
    </S.ContentGrid>
    <S.TopRightInformation item xs={3}>
      {data.awb_number}
    </S.TopRightInformation>

    <S.ContentGrid item xs={3}>
      Pharmacy Name
    </S.ContentGrid>
    <S.ContentGridPharmacyName item xs={9}>
      : {data.pharmacy?.name || ''}
    </S.ContentGridPharmacyName>

    <S.ContentGrid item xs={3}>
      Address
    </S.ContentGrid>
    <S.ContentGridPharmacyAddress item xs={9}>
      : {data.pharmacy?.address || ''}
    </S.ContentGridPharmacyAddress>

    <S.QRCodeWrapper item xs={3}>
      <QRCode value={data.po_number} size={48} />
      <S.QRText>SO Number</S.QRText>
    </S.QRCodeWrapper>

    <S.PharmacyDetails item xs={5}>
      <p>City: {data.pharmacy?.city || ''}</p>
      <p>District: {data.pharmacy?.district || ''}</p>
      <p>Sub-District: {data.pharmacy?.subdistrict || ''}</p>
      <p>Postal Code: {data.pharmacy?.zipcode || ''}</p>
    </S.PharmacyDetails>

    <S.QRCodeWrapper item xs={3}>
      {!!data.awb_number && (
        <>
          <QRCode value={data.awb_number} size={48} />
          <S.QRText>SwipeRX AWB</S.QRText>
        </>
      )}
    </S.QRCodeWrapper>
  </>
);
