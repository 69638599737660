import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { KoliInfo } from 'commons/types/packingTask.interface';

import { IShippingLabelMappedData } from '../../../interfaces';

export const useShippingLabel = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const [koli, setKoli] = useState<KoliInfo[] | null>(null);
  const [labelData, setLabelData] = useState<IShippingLabelMappedData | null>(
    null,
  );

  useEffect(() => {
    try {
      const koliData = JSON.parse(searchParams.get('koli') || '');
      const parsedData = JSON.parse(searchParams.get('data') || '');
      setKoli(koliData);
      setLabelData(parsedData);
    } catch (err: any) {
      setError(err.message as string);
    }
  }, [searchParams]);

  return { koli, labelData, error };
};
