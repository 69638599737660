import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useDebouncedCallback } from 'use-debounce';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { StockTransferStatusEnum } from 'commons/enums';
import { PRIMARY_RED } from 'commons/styles/colors';
import {
  IStockTransfer,
  IStockTransferItemDestination,
  IStockTransferItemProps,
} from 'commons/types';
import {
  getStockTransferBySkuCodeAction,
  postRemoveStockTransferDestinationBatch,
  postStockTransferPutDestinationBin,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';

import { StockTransferItemForm } from './components';
import { StockTransferTaskStyle as S } from './StockTransferTask.style';
import {
  destinationSummaryConfig,
  sourceRecommendationConfig,
} from './table-configs';
import { destinationSourceConfig } from './table-configs/destination-source-config';
import { IDestinationSummary } from './table-configs/types';

export const StockTransferTaskDestinationPage: React.FC = () => {
  const { pathname } = useLocation();
  const pathSkuId = pathname.split('/').reverse()[0];
  const dispatch = useDispatch<AppDispatch>();
  const { stockTransfer, stockTransferSkuList } = useSelector(
    (state: RootReducerInterface) => state.stockTransfer,
  );

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getStockTransferBySkuCodeAction({
        skuCode: pathSkuId,
        updateDestinationRecommendation: true,
        navigate,
      }),
    );
  }, [pathSkuId, dispatch, navigate]);

  const stockTransferTask: IStockTransfer = useMemo(() => {
    const emptyStockTransferTask = {
      transfer_number: '', // get from api, format: `ST-<sku_code>-<short-uuid>`
      status: StockTransferStatusEnum.TRANSFERRING,
      warehouse_id: 0,
      user: '-',
      name: '-',
      sku_code: pathSkuId,
      source_bin: [],
      destination_bin: [],
      quantity: 0,
    };
    return stockTransfer[pathSkuId] || emptyStockTransferTask;
  }, [pathSkuId, stockTransfer]);

  const [placedQty, setPlacedQty] = useState(0);
  useEffect(() => {
    const totalPlacedQty = stockTransferTask.destination_bin.reduce(
      (total, source) => total + source.quantity,
      0,
    );
    setPlacedQty(totalPlacedQty);
  }, [stockTransferTask.destination_bin]);

  const [skuIndex, setSkuIndex] = useState(0);
  useEffect(() => {
    setSkuIndex(stockTransferSkuList.findIndex((sku) => sku === pathSkuId));
  }, [pathSkuId, stockTransferSkuList]);

  const [destinations, setDestinations] = useState<
    Partial<IStockTransferItemProps>[]
  >(structuredClone(stockTransferTask.destination_bin));
  useEffect(() => {
    if (
      stockTransferTask.destination_bin &&
      stockTransferTask.destination_bin.length > 0
    ) {
      setDestinations(structuredClone(stockTransferTask.destination_bin));
    } else {
      setDestinations([{}]);
    }
  }, [stockTransferTask.destination_bin]);

  const handleAddBatch = (): void => {
    const newDestinations = structuredClone(destinations);
    newDestinations.push({});
    setDestinations(newDestinations);
  };

  const debouncePostStockTransferReplaceBatch = useDebouncedCallback(
    (skuCode: string, replacedDestination: IStockTransferItemProps) => {
      postRemoveStockTransferDestinationBatch(
        {
          skuCode,
          removedBin: {
            bin: replacedDestination.bin,
            inventory_number: replacedDestination.inventory_number,
          },
        },
        dispatch,
      );
    },
    500,
    { maxWait: 2000 },
  );

  const handleRemoveBatch = (): void => {
    const newDestinations = structuredClone(destinations);
    const removedDestination = newDestinations.pop();

    if (
      removedDestination &&
      removedDestination.bin &&
      removedDestination.inventory_number
    ) {
      postRemoveStockTransferDestinationBatch(
        {
          skuCode: stockTransferTask.sku_code,
          removedBin: {
            bin: removedDestination.bin,
            inventory_number: removedDestination.inventory_number,
          },
        },
        dispatch,
      );
    }
    const totalPlacedQty = newDestinations.reduce(
      (total, dest) => total + (dest.quantity || 0),
      0,
    );

    setDestinations(newDestinations);
    setPlacedQty(totalPlacedQty);
  };

  const handleReplaceBatch = (
    index: number,
    newItem: Partial<IStockTransferItemProps>,
  ): Partial<IStockTransferItemProps> => {
    const newDestinations = structuredClone(destinations);
    const replacedDestination = newDestinations[index];
    if (
      replacedDestination &&
      replacedDestination.bin &&
      replacedDestination.inventory_number &&
      replacedDestination.quantity
    ) {
      debouncePostStockTransferReplaceBatch(
        stockTransferTask.sku_code,
        replacedDestination as IStockTransferItemProps,
      );
    }
    newDestinations[index] = newItem;
    setDestinations(newDestinations);
    return newItem;
  };

  const sourceBatchQuantity = useMemo(() => {
    return stockTransferTask.source_bin.reduce<
      Record<string, { quantity: number; expiry_date: Date }>
    >((result, bin) => {
      if (result[bin.inventory_number]) {
        return {
          ...result,
          [bin.inventory_number]: {
            ...result[bin.inventory_number],
            quantity: result[bin.inventory_number].quantity + bin.quantity,
          },
        };
      }
      return {
        ...result,
        [bin.inventory_number]: {
          quantity: bin.quantity,
          expiry_date: new Date(bin.expiry_date),
        },
      };
    }, {});
  }, [stockTransferTask.source_bin]);

  const debounceValidateInventoryNumber = useDebouncedCallback(
    (params: { inventoryNumber: string; index: number; bin?: string }) => {
      const validInventoryNumber = Object.keys(sourceBatchQuantity);
      if (!validInventoryNumber.includes(params.inventoryNumber)) {
        const newDestination = {
          bin: params.bin,
          inventory_number: params.inventoryNumber,
          expiry_date: undefined,
          quantity: 0,
        };
        const newDestinations = structuredClone(destinations);
        newDestinations[params.index] = newDestination;
        setDestinations(newDestinations);
        dispatch(
          snackbarSetData({
            open: true,
            message:
              'Invalid Inventory Number: Did not exist in picked source bins.',
            color: PRIMARY_RED,
          }),
        );
      }
    },
    500,
    { maxWait: 2000 },
  );

  const debounceValidateBatchQuantity = useDebouncedCallback(
    (params: { inventoryNumber: string; quantity: number }) => {
      const currentSourceBatch = sourceBatchQuantity[params.inventoryNumber];
      if (
        !currentSourceBatch ||
        currentSourceBatch.quantity < params.quantity
      ) {
        dispatch(
          snackbarSetData({
            open: true,
            message:
              'Invalid Quantity: Quantity replenish is greater than quantity picked from source.',
            color: PRIMARY_RED,
          }),
        );
      }
    },
    500,
    { maxWait: 2000 },
  );

  const handleOnChange = async (
    index: number,
    newItem: Partial<IStockTransferItemProps>,
  ): Promise<Partial<IStockTransferItemProps> | undefined> => {
    const currentDestination = destinations[index];
    if (!currentDestination) {
      return undefined;
    }
    const {
      bin,
      inventory_number: inventoryNumber,
      quantity,
      expiry_date: expiryDate,
    } = newItem;
    if (
      bin === currentDestination.bin &&
      inventoryNumber === currentDestination.inventory_number &&
      quantity === currentDestination.quantity
    ) {
      return undefined;
    }
    if (
      inventoryNumber &&
      inventoryNumber !== currentDestination.inventory_number
    ) {
      debounceValidateInventoryNumber({ inventoryNumber, bin, index });
      if (!expiryDate) {
        const currentSourceBatch = sourceBatchQuantity[inventoryNumber];
        const newExpiryDate = currentSourceBatch?.expiry_date;
        if (newExpiryDate) {
          const newDestination = {
            bin: newItem.bin,
            inventory_number: newItem.inventory_number,
            expiry_date: newExpiryDate,
            quantity: newItem.quantity,
          };
          const newDestinations = structuredClone(destinations);
          newDestinations[index] = newDestination;
          setDestinations(newDestinations);
          return newDestination;
        }
      }
    }
    if (
      inventoryNumber &&
      quantity &&
      quantity !== currentDestination.quantity
    ) {
      debounceValidateBatchQuantity({ inventoryNumber, quantity });
      const totalPlacedQty = destinations.reduce(
        (total, dest, i) =>
          total + (i === index ? quantity : dest.quantity || 0),
        0,
      );
      setPlacedQty(totalPlacedQty);
    }

    return undefined;
  };

  const debouncePostStockTransferPutDestinationBin = useDebouncedCallback(
    (destinationBin: IStockTransferItemDestination) => {
      const skuCode = stockTransferTask.sku_code;
      postStockTransferPutDestinationBin({ skuCode, destinationBin }, dispatch);
    },
    500,
    { maxWait: 2000 },
  );

  const handleUpdateDestination = (
    index: number,
    newDestination: IStockTransferItemProps,
  ): void => {
    const {
      bin,
      inventory_number: inventoryNumber,
      expiry_date: expiryDate,
      quantity,
    } = newDestination;
    const currentDestination = destinations[index];
    if (!currentDestination) {
      return;
    }
    if (
      bin === currentDestination.bin &&
      inventoryNumber === currentDestination.inventory_number &&
      currentDestination.expiry_date &&
      DateTime.fromJSDate(new Date(expiryDate)).hasSame(
        DateTime.fromJSDate(new Date(currentDestination.expiry_date)),
        'day',
      ) &&
      quantity === currentDestination.quantity
    ) {
      /* same data / no need to update to backend */
      return;
    }
    if (bin && inventoryNumber && expiryDate && quantity) {
      const newDestinations = structuredClone(destinations);
      newDestinations[index] = newDestination;
      setDestinations(newDestinations);

      const isRevokedBin = !!stockTransferTask.source_bin.find(
        (source) => source.inventory_number === inventoryNumber,
      );

      debouncePostStockTransferPutDestinationBin({
        ...newDestination,
        is_revoked_bin: isRevokedBin,
      });
    }
  };

  const handleBackToSkuListPage = (): void => {
    navigate('/stock-transfer/list/destination');
  };

  const [nextSku, setNextSku] = useState<string | null>(null);
  useEffect(() => {
    const nextSkuIndex = skuIndex + 1;
    if (
      nextSkuIndex >= 0 &&
      nextSkuIndex < stockTransferSkuList.length &&
      stockTransferSkuList[nextSkuIndex]
    ) {
      setNextSku(stockTransferSkuList[nextSkuIndex]);
    } else {
      setNextSku(null);
    }
  }, [stockTransferSkuList, skuIndex]);

  const handleGoToNextSku = (): void => {
    if (nextSku) {
      navigate(`/stock-transfer/task/destination/${nextSku}`);
    }
  };

  const getDestinationTableData = () => {
    if (!stockTransferTask || !stockTransferTask.destination_bin_infos)
      return [];

    return stockTransferTask.destination_bin_infos;
  };

  return (
    <>
      <Header
        title="Stock Transfer Task | Destination"
        prevPageHandler={handleBackToSkuListPage}
      />

      {/* Body */}
      <S.ContentWrapper>
        {/* Header Section */}
        <S.SubtitleWrapper container>
          <S.Subtitle item xs={12}>
            {pathSkuId}
          </S.Subtitle>
          <S.SubHeaderText>
            {stockTransferTask.name || '-'} <br />
          </S.SubHeaderText>
        </S.SubtitleWrapper>

        {/* Source Bin Section */}
        <S.ItemContentWrapper container>
          <Grid item xs={12}>
            <S.SubHeaderText>Source Bin</S.SubHeaderText>
          </Grid>
          <TableComponent
            data={stockTransferTask.source_bin || []}
            config={destinationSourceConfig}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.ItemContentWrapper>

        {/* Destination Info Section */}
        <S.ItemContentWrapper container>
          <S.ItemContentWrapper container>
            <Grid item xs={12}>
              <S.SubHeaderText>Destination Informations</S.SubHeaderText>
            </Grid>
            <TableComponent
              data={getDestinationTableData()}
              config={sourceRecommendationConfig}
              additionalTableConfig={{ bodyFontSize: '12px' }}
            />
          </S.ItemContentWrapper>
        </S.ItemContentWrapper>

        {/* Form Section */}
        {destinations.map((destination, index) => (
          <StockTransferItemForm
            index={index}
            item={destination}
            type="pick"
            onAllFilled={handleUpdateDestination}
            onChanged={handleOnChange}
            onReplace={handleReplaceBatch}
          />
        ))}

        {/* Batch Actions */}
        <Grid container style={{ marginTop: 8 }}>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <S.RedButtonText
              onClick={handleRemoveBatch}
              disabled={destinations.length === 1}
            >
              REMOVE BATCH
            </S.RedButtonText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <S.BlueButtonText onClick={handleAddBatch}>
              <Add /> ADD BATCH
            </S.BlueButtonText>
          </Grid>
        </Grid>

        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton onClick={handleBackToSkuListPage}>
              BACK TO SKU LIST
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton onClick={handleGoToNextSku} disabled={!nextSku}>
              NEXT SKU
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
    </>
  );
};
