import React from 'react';

import { Grid } from '@mui/material';

import { IShippingLabelMappedData } from '../../interfaces';

import { ShippingLabelContent } from './components/ShippingLabelContent.component';
import { ShippingLabelFooter } from './components/ShippingLabelFooter.component';
import { ShippingLabelHeader } from './components/ShippingLabelHeader.component';
import { useShippingLabel } from './hooks/useShippingLabel';
import S from './ShippingLabelPrintTemplate.styles';

interface ShippingLabelProps {
  data: IShippingLabelMappedData;
  koliIdx: number;
  koliLength: number;
}

const ShippingLabel: React.FC<ShippingLabelProps> = ({
  data,
  koliIdx,
  koliLength,
}) => (
  <Grid container>
    <ShippingLabelHeader
      logoSrc="/assets/swiperx-logo.svg"
      isDigitalSigned={data.is_digital_signed}
      logisticName={data.logistic_name}
    />
    <ShippingLabelContent data={data} />
    <ShippingLabelFooter
      koliIdx={koliIdx}
      koliLength={koliLength}
      baskets={data.baskets}
    />
  </Grid>
);

const ShippingLabelPrintTemplate: React.FC = () => {
  document.body.className = 'print-template';
  const { koli, labelData, error } = useShippingLabel();

  if (error) {
    return <div>Error loading shipping label data: ${error}</div>;
  }

  if (!koli || !labelData) {
    return <div>Data is not loaded</div>;
  }

  return (
    <>
      {koli.map((_, idx) => (
        <S.PageBody>
          <ShippingLabel
            data={labelData}
            koliIdx={idx + 1}
            koliLength={koli.length}
          />
          {idx < koli.length - 1 && (
            <div
              className="pagebreak-after"
              style={{ marginTop: '2mm', width: '100%' }}
            />
          )}
        </S.PageBody>
      ))}
    </>
  );
};

export default ShippingLabelPrintTemplate;
