import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  DashboardType,
  CSVUploadStatus,
  FileImportType,
  ReplenishType,
  OrderSearchCriteriaEnum,
  ReplenishSearchCriteriaEnum,
  StockTransferSearchCriteriaEnum,
} from 'commons/enums';
import { PRIMARY_BLUE, PRIMARY_GREEN } from 'commons/styles/colors';
import {
  DashboardActiveOrderSummaries,
  DashboardShiftSummaries,
} from 'commons/types';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import {
  setActiveLocations,
  setAllOrderLists,
  setAllReplenishLists,
  setAllStockTransferLists,
  setAllUserLists,
  setHighPriorityValue,
  setBulkUploadHistory,
  setSummariesActiveOrder,
  setSummariesShifts,
  setUpdateListPage,
  setHighPriorityValueReplenish,
} from 'redux-stores/reducers/dashboard.reducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { swiperxWmsApiDashboard } from 'services/apis/SwipeRxWmsApiDashboard';
import {
  PackingActiveOrderSummariesResponse,
  PackingShiftSummariesResponse,
  PickingActiveOrderSummariesResponse,
  PickingShiftSummariesResponse,
} from 'services/apis/SwipeRxWmsApiDashboard/types/get-summaries.type';

const defaultSearchParams = new Set(['page', 'page_size']);

export const getOrderListAction = createAsyncThunk(
  'dashboard/getOrderListAction',
  async (
    params: { searchParams?: URLSearchParams; locationId?: string },
    { dispatch },
  ) => {
    const { searchParams, locationId } = params;

    const hasOtherParams =
      searchParams &&
      Array.from(searchParams.keys()).some(
        (key) => !defaultSearchParams.has(key),
      );

    if (hasOtherParams) {
      const queries: Record<string, string | string> = {};
      searchParams?.forEach((value, key) => {
        if (key !== 'status' || value !== OrderSearchCriteriaEnum.ALL)
          queries[key] = value;
      });

      if (locationId) queries.location = locationId;

      defaultActionProcess(async () => {
        const data =
          await swiperxWmsApiDashboard.getOrderListPaginated(queries);
        dispatch(setAllOrderLists(data));
      }, dispatch);
    }
  },
);

export const getReplenishListAction = createAsyncThunk(
  'dashboard/getReplenishListAction',
  async (
    params: { searchParams?: URLSearchParams; locationId?: string },
    { dispatch },
  ) => {
    const { searchParams, locationId } = params;

    const hasOtherParams =
      searchParams &&
      Array.from(searchParams.keys()).some(
        (key) => !defaultSearchParams.has(key),
      );

    if (hasOtherParams) {
      const queries: Record<string, string | string> = {};
      searchParams?.forEach((value, key) => {
        if (key !== 'status' || value !== ReplenishSearchCriteriaEnum.ALL)
          queries[key] = value;
      });

      if (locationId) queries.location = locationId;

      defaultActionProcess(async () => {
        const data =
          await swiperxWmsApiDashboard.getReplenishListPaginated(queries);
        dispatch(setAllReplenishLists(data));
      }, dispatch);
    }
  },
);

export const getUserListAction = createAsyncThunk(
  'dashboard/getUserListAction',
  async (
    params: { searchParams?: URLSearchParams; locationId?: string },
    { dispatch },
  ) => {
    const { searchParams, locationId } = params;
    const queries: Record<string, string | string> = {};
    searchParams?.forEach((value, key) => {
      queries[key] = value;
    });

    if (locationId) queries.location = locationId;

    defaultActionProcess(async () => {
      const data = await swiperxWmsApiDashboard.getUserListPaginated(queries);
      dispatch(setAllUserLists(data));
    }, dispatch);
  },
);

export const getStockTransferListAction = createAsyncThunk(
  'dashboard/getStockTransferListAction',
  async (
    params: { searchParams?: URLSearchParams; locationId?: string },
    { dispatch },
  ) => {
    const { searchParams, locationId } = params;

    const hasOtherParams =
      searchParams &&
      Array.from(searchParams.keys()).some(
        (key) => !defaultSearchParams.has(key),
      );

    if (hasOtherParams) {
      const queries: Record<string, string> = {};
      searchParams?.forEach((value, key) => {
        if (key !== 'status' || value !== StockTransferSearchCriteriaEnum.ALL)
          queries[key] = value;
      });

      if (locationId) queries.location = locationId;

      defaultActionProcess(async () => {
        const data =
          await swiperxWmsApiDashboard.getStockTransferListPaginated(queries);
        dispatch(setAllStockTransferLists(data));
      }, dispatch);
    }
  },
);

export const getActiveOrderSummaries = createAsyncThunk(
  'dashboard/getActiveOrderSummaries',
  async (
    params: { location: number | undefined; type: DashboardType },
    { dispatch },
  ) => {
    const { location, type } = params;
    defaultActionProcess(async () => {
      let data: DashboardActiveOrderSummaries | null;
      let rawData:
        | PickingActiveOrderSummariesResponse
        | PackingActiveOrderSummariesResponse
        | null;
      switch (type) {
        case DashboardType.PICKING:
          rawData =
            await swiperxWmsApiDashboard.getPickingSummariesActiveOrders(
              location,
            );
          data = {
            currentActiveUserCount: rawData?.currentActivePickerCount || 0,
            orderCounts: rawData?.orderCounts || {
              alkes: { in_progress: 0, in_queue: 0 },
              precursor: { in_progress: 0, in_queue: 0 },
              regular: { in_progress: 0, in_queue: 0 },
            },
          };
          break;
        case DashboardType.PACKING:
          rawData =
            await swiperxWmsApiDashboard.getPackingSummariesActiveOrders(
              location,
            );
          data = {
            currentActiveUserCount: rawData?.currentActivePackerCount || 0,
            orderCounts: rawData?.orderCounts || {
              alkes: { in_progress: 0, in_queue: 0 },
              precursor: { in_progress: 0, in_queue: 0 },
              regular: { in_progress: 0, in_queue: 0 },
            },
          };
          break;
        default:
          data = null;
          break;
      }
      // await swiperxWmsApiDashboard.getPickingSummariesActiveOrders(location);
      if (data) {
        dispatch(setSummariesActiveOrder(data));
      }
    }, dispatch);
  },
);

export const getShiftSummaries = createAsyncThunk(
  'dashboard/getShiftSummaries',
  async (
    params: { location: number | undefined; type: DashboardType },
    { dispatch },
  ) => {
    const { location, type } = params;
    defaultActionProcess(async () => {
      let data: DashboardShiftSummaries | null;
      let rawData:
        | PickingShiftSummariesResponse
        | PackingShiftSummariesResponse
        | null;
      switch (type) {
        case DashboardType.PICKING:
          rawData =
            await swiperxWmsApiDashboard.getPickingSummariesShift(location);
          data = {
            orderPerShifts: (rawData?.orderPickedPerShifts || []).map(
              (item) => ({
                order_count: item.picked,
                shift: item.shift,
              }),
            ),
            topUsers: (rawData?.topPickers || []).map((item) => ({
              user: item.picker,
              count: item.picked,
            })),
          };
          break;
        case DashboardType.PACKING:
          rawData =
            await swiperxWmsApiDashboard.getPackingSummariesShift(location);
          data = {
            orderPerShifts: (rawData?.orderPackedPerShifts || []).map(
              (item) => ({
                order_count: item.packed,
                shift: item.shift,
              }),
            ),
            topUsers: (rawData?.topPackers || []).map((item) => ({
              user: item.packer,
              count: item.packed,
            })),
          };
          break;
        default:
          data = null;
          break;
      }
      if (data) {
        dispatch(setSummariesShifts(data));
      }
    }, dispatch);
  },
);

export const prioritizeOrderPick = createAsyncThunk(
  'dashboard/prioritizeOrderPick',
  async (payload: { poNumber: string; location?: number }, { dispatch }) => {
    defaultActionProcess(async () => {
      const { poNumber, location } = payload;
      const success = await swiperxWmsApiDashboard.prioritizeOrderPick(
        poNumber,
        location,
      );

      if (success) {
        dispatch(
          setHighPriorityValue({
            po_numbers: [poNumber],
            value: true,
          }),
        );
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize PO ${poNumber} success!`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize PO ${poNumber} cannot be prioritized`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkPrioritizeOrderPick = createAsyncThunk(
  'dashboard/bulkPrioritizeOrderPick',
  async (payload: { poNumbers: string[]; location?: number }, { dispatch }) => {
    defaultActionProcess(async () => {
      const { poNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkPrioritizeOrderPick(
        poNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize PO success! \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize PO success: \n ${result.success.join(
              '\n',
            )} \n while PO skipped: \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }

      // update state
      if (result.success.length > 0) {
        dispatch(
          setHighPriorityValue({ po_numbers: result.success, value: true }),
        );
      }
    }, dispatch);
  },
);

export const unPrioritizeOrderPick = createAsyncThunk(
  'dashboard/unPrioritizeOrderPick',
  async (payload: { poNumber: string; location?: number }, { dispatch }) => {
    defaultActionProcess(async () => {
      const { poNumber, location } = payload;
      const success = await swiperxWmsApiDashboard.unPrioritizeOrderPick(
        poNumber,
        location,
      );

      if (success) {
        dispatch(
          setHighPriorityValue({
            po_numbers: [poNumber],
            value: false,
          }),
        );
        dispatch(
          snackbarSetData({
            open: true,
            message: `Unprioritize PO ${poNumber} success!`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Unprioritize PO ${poNumber} cannot be unprioritized`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkUnPrioritizeOrderPick = createAsyncThunk(
  'dashboard/bulkUnPrioritizeOrderPick',
  async (payload: { poNumbers: string[]; location?: number }, { dispatch }) => {
    defaultActionProcess(async () => {
      const { poNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkUnPrioritizeOrderPick(
        poNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Unprioritize PO success! \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Unprioritize PO success: \n ${result.success.join(
              '\n',
            )} \n while PO skipped: \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }

      // update state
      if (result.success.length > 0) {
        dispatch(
          setHighPriorityValue({ po_numbers: result.success, value: false }),
        );
      }
    }, dispatch);
  },
);

export const retryUpdateFailedOrder = createAsyncThunk(
  'dashboard/retryUpdateFailedOrder',
  async (payload: { poNumber: string; location?: number }, { dispatch }) => {
    const { poNumber, location } = payload;
    defaultActionProcess(async () => {
      const success = await swiperxWmsApiDashboard.retryUpdate(
        poNumber,
        location,
      );

      if (success) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit retry PO ${poNumber} done!`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit retry PO ${poNumber} failed!`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkRetryUpdateFailedOrder = createAsyncThunk(
  'dashboard/bulkRetryUpdateFailedOrder',
  async (payload: { poNumbers: string[]; location?: number }, { dispatch }) => {
    defaultActionProcess(async () => {
      const { poNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkRetryUpdate(
        poNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry PO done! \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry PO done: \n ${result.success.join(
              '\n',
            )} \n while PO skipped: \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const getWMSActiveLocations = createAsyncThunk(
  'dashboard/getWMSActiveLocations',
  async (_: never, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swiperxWmsApiDashboard.getActiveLocations();
      if (data) {
        dispatch(setActiveLocations(data));
      }
    }, dispatch);
  },
);

export const prioritizeReplenishTask = createAsyncThunk(
  'dashboard/prioritizeReplenishTask',
  async (
    payload: { replenishNumber: string; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { replenishNumber, location } = payload;
      const success = await swiperxWmsApiDashboard.prioritizeReplenishTask(
        replenishNumber,
        location,
      );

      if (success) {
        dispatch(
          setHighPriorityValueReplenish({
            replenish_numbers: [replenishNumber],
            value: true,
          }),
        );
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize Replenish ${replenishNumber} success!`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize Replenish ${replenishNumber} cannot be prioritized`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkPrioritizeReplenishTask = createAsyncThunk(
  'dashboard/bulkPrioritizeReplenishTask',
  async (
    payload: { replenishNumbers: string[]; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { replenishNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkPrioritizeReplenishTask(
        replenishNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize Replenish success!
                      \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize Replenish success: \n ${result.success.join(
              '\n',
            )} \n while Replenish skipped: \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }

      // update state
      if (result.success.length > 0) {
        dispatch(
          setHighPriorityValueReplenish({
            replenish_numbers: result.success,
            value: true,
          }),
        );
      }
    }, dispatch);
  },
);

export const unPrioritizeReplenishTask = createAsyncThunk(
  'dashboard/unPrioritizeReplenishTask',
  async (
    payload: { replenishNumber: string; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { replenishNumber, location } = payload;
      const success = await swiperxWmsApiDashboard.unPrioritizeReplenishTask(
        replenishNumber,
        location,
      );

      if (success) {
        dispatch(
          setHighPriorityValueReplenish({
            replenish_numbers: [replenishNumber],
            value: false,
          }),
        );
        dispatch(
          snackbarSetData({
            open: true,
            message: `Unprioritize Replenish ${replenishNumber} success!`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Replenish ${replenishNumber} cannot be unprioritized`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkUnPrioritizeReplenishTask = createAsyncThunk(
  'dashboard/bulkUnPrioritizeReplenishTask',
  async (
    payload: { replenishNumbers: string[]; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { replenishNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkUnPrioritizeReplenishTask(
        replenishNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Unprioritize Replenish success!
                      \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Unprioritize Replenish success: \n ${result.success.join(
              '\n',
            )} \n while Replenish skipped: \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }

      // update state
      if (result.success.length > 0) {
        dispatch(
          setHighPriorityValueReplenish({
            replenish_numbers: result.success,
            value: false,
          }),
        );
      }
    }, dispatch);
  },
);

export const retryUpdateFailedOrderReplenish = createAsyncThunk(
  'dashboard/retryUpdateFailedOrderReplenish',
  async (
    payload: { replenishNumber: string; location?: number },
    { dispatch },
  ) => {
    const { replenishNumber, location } = payload;
    defaultActionProcess(async () => {
      const success = await swiperxWmsApiDashboard.retryUpdateReplenish(
        replenishNumber,
        location,
      );

      if (success) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit retry Replenish Task ${replenishNumber} done!`,
            color: PRIMARY_GREEN,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkRetryUpdateFailedReplenish = createAsyncThunk(
  'dashboard/bulkRetryUpdateFailedReplenish',
  async (
    payload: { replenishNumbers: string[]; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { replenishNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkRetryUpdateReplenish(
        replenishNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry Replenish Task done!
            \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry Replenish Task done: \n ${result.success.join(
              '\n',
            )} \n while Replenish Task skipped:
            \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const removeReplenishQueue = createAsyncThunk(
  'dashboard/removeReplenishQueue',
  async (
    payload: { replenishType: ReplenishType; location?: number },
    { dispatch },
  ) => {
    const { replenishType, location } = payload;
    defaultActionProcess(async () => {
      const result = await swiperxWmsApiDashboard.removeQueue(
        replenishType,
        location,
      );

      if (result) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Queue successfully removed: ${result.deletedCount} task(s)!`,
            color: PRIMARY_BLUE,
          }),
        );
        if (result.deletedCount > 0) {
          dispatch(setUpdateListPage());
        }
      }
    }, dispatch);
  },
);

export const bulkRetryUpdateFailedStockTransfer = createAsyncThunk(
  'dashboard/bulkRetryUpdateFailedStockTransfer',
  async (
    payload: { transferNumbers: string[]; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { transferNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.retryUpdateStockTransfer(
        transferNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry Stock Transfer done!
            \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry Stock Transfer done: \n ${result.success.join(
              '\n',
            )} \n while Stock Transfer skipped:
            \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkUploadProduct = createAsyncThunk(
  'dashboard/bulkUploadProduct',
  async (
    payload: { file: File; fileImportType: FileImportType; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { file, fileImportType, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkUploadProduct(
        file,
        fileImportType,
        location,
      );

      switch (result.status) {
        case CSVUploadStatus.Warning:
          dispatch(
            snackbarSetData({
              open: true,
              message: `"${result.file_name}" uploaded with warning: ${result.invalid_rows} invalid rows`,
              color: PRIMARY_BLUE,
            }),
          );
          return;
        case CSVUploadStatus.Processing:
          dispatch(
            snackbarSetData({
              open: true,
              message: `"${result.file_name}" is queued for processing`,
              color: PRIMARY_BLUE,
            }),
          );
          return;
        default:
          dispatch(
            snackbarSetData({
              open: true,
              message: `"${result.file_name}" uploaded successfully`,
              color: PRIMARY_GREEN,
            }),
          );
      }
    }, dispatch);
  },
);

export const getBulkUploadHistoryAction = createAsyncThunk(
  'dashboard/getProductUploadHistoryAction',
  async (
    params: { searchParams?: URLSearchParams; locationId?: string },
    { dispatch },
  ) => {
    const { searchParams, locationId } = params;
    const queries: Record<string, string | string> = {};
    searchParams?.forEach((value, key) => {
      queries[key] = value;
    });

    if (locationId) queries.location = locationId;

    defaultActionProcess(async () => {
      const data =
        await swiperxWmsApiDashboard.getBulkUploadHistoryPaginated(queries);
      dispatch(setBulkUploadHistory(data));
    }, dispatch);
  },
);

export const completePackingDashboard = createAsyncThunk(
  'dashboard/completePacking',
  async (payload: { poNumber: string; location?: number }, { dispatch }) => {
    const { poNumber, location } = payload;
    defaultActionProcess(async () => {
      await swiperxWmsApiDashboard.completePacking(poNumber, location);
    }, dispatch);
    dispatch(
      snackbarSetData({
        open: true,
        message: `Successfully updated PO ${poNumber} to packed`,
        color: PRIMARY_GREEN,
      }),
    );
    dispatch(setUpdateListPage());
  },
);
