import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonGroup, Divider, Grid, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import ConfirmationDialog from 'commons/components/ConfirmationDialog/ConfirmationDialog.component';
import {
  CriteriaOption,
  SearchWithCriteria,
} from 'commons/components/SearchRelated';
import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import {
  ReplenishSearchCriteriaEnum,
  ReplenishTaskStatusEnum,
  ReplenishType,
} from 'commons/enums';
import {
  getReplenishListAction,
  removeReplenishQueue,
} from 'redux-stores/actions/dashboard.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { ListPage, ListFilterCard } from '../commons/components';

import ReplenishTable from './components/ReplenishTable.component';

const ReplenishListPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { queryParams, updateQueryParams } = useUpdateQueryParams();

  const { replenishList, selectedLocation } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const updateReplenishListSearchDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getReplenishListAction({
          searchParams: _searchParams,
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  const searchCriteria: CriteriaOption[] = [
    {
      name: 'SKU',
      id: 'sku_code',
      isArray: true,
      parameterMapping: 'sku_codes',
    },
    {
      name: 'Replenisher',
      id: 'replenish_user',
    },
    {
      name: 'Replenish Type',
      id: 'type',
    },
  ];

  const replenishTaskStatusArrayText: {
    key: ReplenishSearchCriteriaEnum;
    text: string;
  }[] = [
    ...Object.values(ReplenishSearchCriteriaEnum).map((value) => ({
      key: value,
      text: value.replace('-', ' '),
    })),
  ];

  const [statusFilter, setStatusFilter] =
    useState<ReplenishSearchCriteriaEnum | null>(null);
  const [openRemoveQueueModal, setOpenRemoveQueueModal] =
    useState<boolean>(false);
  const [removeReplenishType, setRemoveReplenishType] = useState<ReplenishType>(
    ReplenishType.LOW_STOCK,
  );

  const handleStatusFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ReplenishSearchCriteriaEnum,
  ): void => {
    setStatusFilter(value);
    updateQueryParams({ status: value }, { clearQueryParams: ['page'] });
  };

  const handleClickRemoveQueueButton = (type: ReplenishType) => {
    setOpenRemoveQueueModal(true);
    setRemoveReplenishType(type);
  };

  const handleRemoveQueueOnConfirm = () => {
    dispatch(
      removeReplenishQueue({
        replenishType: removeReplenishType,
        location: selectedLocation?.warehouse_id,
      }),
    ).then(() => {
      setOpenRemoveQueueModal(false);
    });
  };

  /**
   * Additional Feature to remove Replenish Queue by Replenish Type
   */
  const renderRemoveQueueFeature = () => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
            }}
            variant="inherit"
            component="p"
          >
            Remove Queue
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <ButtonGroup variant="outlined">
            <Button
              onClick={() =>
                handleClickRemoveQueueButton(ReplenishType.LOW_STOCK)
              }
            >
              <Typography variant="button">Low Stock</Typography>
            </Button>
            <Button
              onClick={() =>
                handleClickRemoveQueueButton(ReplenishType.PICKER_REQUEST)
              }
            >
              <Typography variant="button">Picker Request</Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <ConfirmationDialog
        title="Remove Replenish Queue"
        content={`This action will remove all replenish queue with <b>${removeReplenishType.replace(
          '_',
          ' ',
        )}</b> type. Continue?`}
        onClose={() => setOpenRemoveQueueModal(false)}
        openModal={openRemoveQueueModal}
        onConfirm={handleRemoveQueueOnConfirm}
      />
    </>
  );

  return (
    <ListPage updateListSearchDebounce={updateReplenishListSearchDebounce}>
      <ListFilterCard
        statusArrayText={replenishTaskStatusArrayText}
        statusFilter={statusFilter}
        handleStatusFilter={handleStatusFilter}
        searchCriteria={searchCriteria}
      >
        {renderRemoveQueueFeature()}
      </ListFilterCard>
      {Object.keys(queryParams).includes('status') ? (
        <ReplenishTable data={replenishList} statusFilter={statusFilter} />
      ) : (
        <Grid container justifyContent="center">
          <Typography>Please select a status</Typography>
        </Grid>
      )}
    </ListPage>
  );
};

export default ReplenishListPage;
