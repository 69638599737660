import React, { useState } from 'react';

import { binSkuSearchStyle as S } from './bin-sku-search.style';

interface BinSkuSearchProps {
  onAddClicked: (sku: string, bin: string) => void;
}

export const BinSkuSearch: React.FC<BinSkuSearchProps> = ({ onAddClicked }) => {
  const [skuSearch, setSkuSearch] = useState('');
  const [binSearch, setBinSearch] = useState('');

  const onSearchChange = (params: 'sku' | 'bin', value: string): void => {
    switch (params) {
      case 'sku':
        setSkuSearch(value);
        break;
      case 'bin':
        setBinSearch(value);
        break;
      default:
        break;
    }
  };

  return (
    <S.ContentWrapper>
      <S.SearchBar
        placeholder="SKU Number or Scan"
        variant="outlined"
        value={skuSearch}
        onChange={(e) => {
          onSearchChange('sku', e.target.value);
        }}
      />
      <S.SearchBar
        placeholder="Bin Number or Scan"
        variant="outlined"
        value={binSearch}
        onChange={(e) => {
          onSearchChange('bin', e.target.value);
        }}
      />
      <S.AddButton
        onClick={() => {
          onAddClicked(skuSearch.trim(), binSearch.trim());
        }}
        disabled={!(!!skuSearch && !!binSearch)}
      >
        Add
      </S.AddButton>
    </S.ContentWrapper>
  );
};
