import React from 'react';

import { IPackingTask } from 'commons/types/packingTask.interface';
import { TableConfig } from 'commons/types/tableConfig.interface';
import { formatDate } from 'commons/utils/formatDate';

const formatElement = (
  input?: number[] | number | string,
  bold = false,
): React.JSX.Element => {
  const text = input?.toString();
  return <div style={{ fontWeight: bold ? '700' : '400' }}>{text}</div>;
};

const createDate = (input: string | Date): string => {
  // early return if input is date
  if (input instanceof Date) {
    return formatDate(new Date(), 'dd/LL/yyyy, HH:mm');
  }

  // handle input type string
  const now = new Date();
  // assume the input string format is HH:mm
  const [hour, minute] = input.split(':').map(Number);
  const expectedDispatchTime = new Date();

  expectedDispatchTime.setHours(hour, minute, 0, 0);

  if (expectedDispatchTime < now) {
    expectedDispatchTime.setDate(expectedDispatchTime.getDate() + 1);
  }

  return formatDate(expectedDispatchTime, 'dd/LL/yyyy, HH:mm');
};

export const poDataConfig: TableConfig = [
  {
    header: 'PoNumber',
    key: 'po_number',
  },
  {
    header: 'Priority',
    element: (task: IPackingTask): React.ReactElement => {
      return formatElement(task.picking_task?.priority);
    },
  },
  {
    header: 'Basket(s)',
    element: (task: IPackingTask): React.ReactElement => {
      return formatElement(task.picking_task?.baskets);
    },
  },
  {
    header: 'Picker',
    element: (task: IPackingTask): React.ReactElement => {
      return formatElement(task.picking_task?.picker || '-');
    },
  },
  {
    header: 'Expected Dispatch',
    element: (task: IPackingTask): React.ReactElement => {
      const expectedDispatchTime =
        task.picking_task?.expected_dispatch_time &&
        task.picking_task.expected_dispatch_time !== ''
          ? createDate(task.picking_task.expected_dispatch_time)
          : '-';
      return formatElement(expectedDispatchTime);
    },
  },
  {
    header: 'Total SKU',
    element: (task: IPackingTask): React.ReactElement => {
      return formatElement(task.picking_task?.total_sku, true);
    },
  },
  {
    header: 'Total QTY',
    element: (task: IPackingTask): React.ReactElement => {
      const totalQty = task.picking_task.total_qty;
      const pickActualQty = task.basket_infos.reduce((total, basketInfo) => {
        return (
          total +
          basketInfo.items.reduce((basketTotal, item) => {
            return basketTotal + item.total_qty_picked;
          }, 0)
        );
      }, 0);
      return formatElement(`${pickActualQty}/${totalQty}`);
    },
  },
];
