import React from 'react';

import { Grid } from '@mui/material';

import S from '../ShippingLabelPrintTemplate.styles';

interface ShippingLabelHeaderProps {
  logoSrc: string;
  isDigitalSigned: boolean;
  logisticName: string;
}

export const ShippingLabelHeader: React.FC<ShippingLabelHeaderProps> = ({
  logoSrc,
  isDigitalSigned,
  logisticName,
}) => (
  <>
    <Grid item xs={4}>
      <S.SwipeRxLogo src={logoSrc} alt="Logo" />
    </Grid>
    <Grid item xs={5}>
      <S.DigitalSignatureLabel>
        {isDigitalSigned ? 'SP Electronic' : 'SP Manual'}
      </S.DigitalSignatureLabel>
    </Grid>
    <S.TopRightInformation item xs={3}>
      <p>{logisticName}</p>
    </S.TopRightInformation>
  </>
);
