import React from 'react';

import { Grid } from '@mui/material';

import S from '../ShippingLabelPrintTemplate.styles';

interface ShippingLabelFooterProps {
  koliIdx: number;
  koliLength: number;
  baskets?: number[];
}

export const ShippingLabelFooter: React.FC<ShippingLabelFooterProps> = ({
  koliIdx,
  koliLength,
  baskets,
}) => (
  <>
    <Grid item xs={4} />
    <Grid item xs={4} alignContent="center">
      <S.PageNumber>
        {koliIdx} / {koliLength}
      </S.PageNumber>
    </Grid>
    <S.BasketGrid item xs={4}>
      Basket: {baskets?.join(', ')}
    </S.BasketGrid>
  </>
);
